import React from "react";
import Banner from "../components/Banner";
import WhatServiceDo from "../components/service/WhatServiceDo";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `UX Consulting <span class="h1-span">Company</span>`,
    content:
      "We offer strategic UI UX consulting services aligned with your goals to create a seamless user experience across multiple customer touchpoints.",
  };
  const whatServiceContent = {
    title: `What do UX Consulting <span class="h1-span">Consultancy do?</span>`,
    content: [
      "A UX Design Consultancy provides comprehensive UX consulting services to help businesses create exceptional user experiences. They leverage their expertise in user research, interaction design, and usability testing to guide clients through the design process.",
      "As the leading UI UX design consultancy company, we offer services ranging from conducting in-depth user needs analyses to designing intuitive interfaces and optimizing digital experiences.",
      "By combining strategic thinking, creative problem-solving, and a user-centric approach, we empower our clients to deliver products and services that truly resonate with their target audience.",
      "Whether you need to revamp an existing application, build a new website, or innovate within your industry, we are committed to unlocking the full potential of your digital experiences."
    ],
    img: ["what-service-image-consulting.webp"],
    imageTitle: 'UX Consulting Agency',
    imageAlt: '',
  };

  const commonContent = {
    title: `Who needs UX Consulting <span class="h1-span">Services?</span>`,
    para: "UX consulting services support businesses of all sizes and industries seeking to optimize their digital products and services. Our user experience design consultancy helps organizations create more intuitive and engaging experiences that drive user satisfaction and business success.",
    img: ["who-needs-consulting.webp"],
    imageTitle: 'UX Consultation Services',
    imageAlt: '',
    content: [
      {
        title: "Technology Giants",
        text: "Companies who want to increase user engagement by leveraging user-centered design principles and professional guidance.",
      },
      {
        title: "Startups",
        text: "Companies that look to build a long-lasting relationship with customers by optimizing their user experience, driving satisfaction and business success.",
      },
      {
        title: "E-Commerce Platforms",
        text: "Companies who want to optimize their user interface and streamline processes for increased conversions and customer retention.",
      },
      {
        title: "Financial Institutions",
        text: "Companies who want to offer seamless digital interactions, enhance security, and optimize overall performance for increased user adoption.",
      },
    ],
  };

  const commonContent2 = {
    title: "Why work with a UX Consulting Company?",
    para: "A UX consulting company offers strategic recommendations to improve the overall performance of your digital products through its expertise across various industries.",
    img: ["what-are-benefits-consulting.webp"],
    imageTitle: 'Benefits of UX Consulting',
    imageAlt: '',
    content: [
      {
        title: "Objective Perspective",
        text: "A UX consultancy provides unbiased insights into the user experience. They evaluate, identify, and make data-driven decisions to enhance digital offerings.",
      },
      {
        title: "Flexible Approach",
        text: "A UX consulting service offers tailor-made methods and processes specific to your business. These services ensure that you meet your unique business challenges.",
      },
      {
        title: "Streamlined Process",
        text: "A UX design consultancy ensures smooth business flow by delivering impactful business outcomes and efficiently organizing each phase of the digital product.",
      },
      {
        title: "Cost-Effective Solution",
        text: "UX consulting services leverage their expertise to reduce the number of trials and errors, resulting in optimized resources and strategic investments.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why choose Octet as your UX Consulting Agency?",
    linkText: ``,
    para: `We are a leading <a href="/">UI UX design agency</a> consulting company with the required industry expertise. We conduct market research, analyze and evaluate the trends, and use a user-centric approach to enhance your digital offerings.`,
    img: ["why-opt-for-consulting.webp"],
    imageTitle: 'UX Consulting Company in India',
    imageAlt: '',
    content: [
      {
        title: "Diversified Expertise",
        text: "We have a strong foundation of diversified skill sets through which we understand challenges and offer insights to create impactful solutions.",
      },
      {
        title: "Industrial Insights",
        text: "We stay updated with the market trends to offer feedback that aligns with your industry and business requirements to help you make informed decisions.",
      },
      {
        title: "Continuous Support",
        text: "We believe in continuous collaboration and offer ongoing support to assist with your UX strategies and ensure optimized digital offerings.",
      },
      {
        title: "Holistic UX Services",
        text: "We cover every aspect of UX consultancy to offer in-depth solutions for seamless, intuitive, and engaging user experience across all customer touchpoints.",
      },
    ],
  };
  const commonContent4 = {
    title: "How do we offer UX <span>Consultation Services?</span>",
    para: "Our UX design consultancy delivers exceptional services customized according to your business requirements. We conduct thorough. user research and develop strategies to offer tailored recommendations for long-term growth.",
    img: ["how-we-conduct-consulting.webp"],
    imageTitle: 'UX Consultation Process',
    imageAlt: '',
    content: [
      {
        title: "1. Define Goals",
        text: "We define UX goals and set logical milestones to get an accurate idea based on the scope of work and the time required to achieve them.",
      },
      {
        title: "4. Design & Test",
        text: "Once the above is in place, we develop your product's user flows, wireframes, and information architecture.",
      },
      {
        title: "2. Formulate Problem",
        text: "We clarify the target users' needs and preferences so that the final product effectively fulfills them.",
      },
      {
        title: "5. Calculate the ROI",
        text: "Our UX Consultant measure key metrics such as filling out the lead capture form, new sign-ups, conversions & sales, and Net Promoter Score (NPS).",
      },
      {
        title: "3. Define Key UX Metrics",
        text: "We define the most important metrics based on industry benchmarks, which provide a frame of reference for measuring the success of UX efforts.",
      },
    ],
  };
  const commonContent5 = {
    title: `What do you gain from <span class="h1-span">UX Consultation Services?</span>`,
    para: "Investing in user experience design consultancy services can leverage your business performance through improved user experience, increased engagement, higher conversions, etc., and enhance your digital product by offering user insights.",
    content: [
      {
        title: "69% Loyal Customers",
        text: `Companies that understand their audience needs and improve their offerings accordingly get more loyal customers.`,
      },
      {
        title: "60% Better UX Preference",
        text: "UX consulting services identify the user needs and offer better usability services, as 60% consider them crucial.",
      },
      {
        title: "80% Better UX Preference",
        text: "Investing in UI UX consulting services results in a positive customer experience, as consumers consider speed, user-friendliness, etc., essential design elements.",
      },
    ],
    desc: "*These data are from public sources, we do not claim to owe these stats.",
  };
  const cta = {
    title: "Get expert UX consultation for an exceptional experience!",
  };
  const cta2 = {
    title: "Want us to unlock UX <br/>insights for you?",
  };
  const cta3 = {
    title: "We have the expertise to create excellent UX!",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
      title: "Kantime",
      desc: "A Hospice EMR Solutions Platform",
      Industry: "HealthTech",
      firstAlt: "",
      firstTitle: "Performance and Schedule Screen",
      secondAlt: "",
      secondTitle: "Accounts Details",
      WhatWeDid: [
        {
            title: 'UX Consultation',
            link: '/ux-consulting/'
        },
        {
            title: 'UI UX Designing',
            link: '/ui-ux-designing/'
        },
        {
            title: 'Design System Engineering',
            link: '/design-systems/'
        }
      ],
      link: '/project/kantime/',
      images: ["kantime-project.webp", "kantime-project-2.webp"],
    },
    {
      title: "Hushly",
      desc: "A B2B MarTech Platform",
      Industry: "Martech",
      firstAlt: "",
      firstTitle: "Product Page",
      secondAlt: "",
      secondTitle: "Product Details Page",
      WhatWeDid: [
        {
            title: 'UX Consultation',
            link: '/ux-consulting/'
        },
        {
            title: 'UI UX Designing',
            link: '/ui-ux-designing/'
        },
        {
            title: 'Design System Engineering',
            link: '/design-systems/'
        }
      ],
      link: '/project/hushly/',
      images: ["hushly-project.webp", "hushly-project-2.webp"],
    },
  ];

  const Questions = [
    "What can I expect from your UX Consultation Services?",
    "Can your UX consulting company integrate consultation into our development process?",
    "Do you offer remote UX Consultation?",
    "How long does UX Consultation take?",
    "What deliverables can I expect from your UX design consultancy?",
    "How much do you charge for UX Consultation Services?",
  ];
  const clientsTitle = `Companies we offered <span class="h1-span">our UX Consultation</span>`;
  const faqDes = `We understand you may have more questions about our UX consultation services. So, we have compiled some common questions our clients asked about our UX consultant. However, if you still have any questions, please <a href="/contact-us/" title="Contact Us">contact us</a>. `;

  const faqData = [
    {
      para: [
        `We conduct a thorough evaluation of your digital product and its user experience. This includes assessing your current UX design, usability testing, user research, and analyses of KPIs. We then offer actionable insights to improve the overall user experience of your digital product.`,
        `Through our flexible approach, transparent communication, and user-centric attention, we deliver tangible results that drive long-term business success.`,
      ],
    },
    {
      para: [
        `Yes, we follow a flexible approach while offering UX consultation services, allowing us to integrate our solutions into your existing development process seamlessly.`,
        `We can collaborate with your team at different stages, such as wireframing, prototyping, testing, etc., to ensure that our solutions are incorporated effectively, ultimately enhancing user experience.`,
        `We aim to achieve optimal results by seamlessly integrating our feedback and recommendations into your digital offerings.`,
      ],
    },
    {
      para: [
        `We offer remote UX consultation to meet our client's project needs. We collaborate and communicate effectively through online collaborative tools, virtual platforms, etc.`,
        `We provide regular updates, weekly reports, and timely deliverables to ensure smooth and productive collaboration.`,
      ],
    },
    {
      para: [
        `The duration of our UX consultation services varies depending on the scope and complexity of the project. We focus on offering tailor-made solutions, so we take sufficient time to understand your target audience and project goals, conduct thorough market research, and offer valuable insights.`,
        `We aim to deliver qualitative and quantitative results within the set timeline through our UX consultation services.`,
      ],
    },
    {
      para: [
        `We provide a detailed report summarizing the findings and recommendations of our research, wireframes, and prototypes to show the required design improvements, user personas, and other documents to guide UX improvements. However, these deliverables vary depending on your project goals and requirements.`,
      ],
    },
    {
      para: [
        `Our pricing structure varies depending on various factors such as the scope and complexity of the project, duration, level of expertise required on your project, etc. We offer flexible pricing options and request you <a target="_blank" rel="noreferrer" href="/contact-us/">contact us</a> for a detailed quote.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "1. What can I expect from your UX Consultation Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We conduct a thorough evaluation of your digital product and its user experience. This includes assessing your current UX design, usability testing, user research, and analyses of KPIs. We then offer actionable insights to improve the overall user experience of your digital product. 
            
            Through our flexible approach, transparent communication, and user-centric attention, we deliver tangible results that drive long-term business success."
                }
              },{
                "@type": "Question",
                "name": "2. Can your UX consulting company integrate consultation into our development process?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we follow a flexible approach while offering UX consultation services, allowing us to integrate our solutions into your existing development process seamlessly. 
            
            We can collaborate with your team at different stages, such as wireframing, prototyping, testing, etc., to ensure that our solutions are incorporated effectively, ultimately enhancing user experience.
             
            We aim to achieve optimal results by seamlessly integrating our feedback and recommendations into your digital offerings."
                }
              },{
                "@type": "Question",
                "name": "3. Do you offer remote UX Consultation?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer remote UX consultation to meet our client's project needs. We collaborate and communicate effectively through online collaborative tools, virtual platforms, etc.
            
            We provide regular updates, weekly reports, and timely deliverables to ensure smooth and productive collaboration."
                }
              },{
                "@type": "Question",
                "name": "4. How long does UX Consultation take?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The duration of our UX consultation services varies depending on the scope and complexity of the project. We focus on offering tailor-made solutions, so we take sufficient time to understand your target audience and project goals, conduct thorough market research, and offer valuable insights. 
            
            We aim to deliver qualitative and quantitative results within the set timeline through our UX consultation services."
                }
              },{
                "@type": "Question",
                "name": "5. What deliverables can I expect from your UX design consultancy?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We provide a detailed report summarizing the findings and recommendations of our research, wireframes, and prototypes to show the required design improvements, user personas, and other documents to guide UX improvements. However, these deliverables vary depending on your project goals and requirements."
                }
              },{
                "@type": "Question",
                "name": "6. How much do you charge for UX Consultation Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our pricing structure varies depending on various factors such as the scope and complexity of the project, duration, level of expertise required on your project, etc. We offer flexible pricing options and request you contact us for a detailed quote."
                }
              }]
            }            
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <WhatServiceDo data={whatServiceContent} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                See how we’ve made an impact
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection num={index} lastLength={projectData} data={data} />
              </div>
            ))}
          </section>
          <Testimonials title={'Our Client’s Take on Us'} />
          <Cta data={cta2} />
          <CommonServiceComponent data={commonContent5} condition mode={"light"} />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="UX Consulting Company | UI UX Consulting Services" description="We elevate your user experiences with our UX consultation services & bring our expertise to deliver user insights for optimized digital products." />
)